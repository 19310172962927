body {
  font-family: PT Sans, Verdana, Geneva, Tahoma, sans-serif;
  background-color: #282c34;
}

.App {
  text-align: center;
  padding: 0 10%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(231, 231, 231);
}

.App-logo {
  height: 40vmin;
}

.App-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  font-weight: bold;
  color: #3aa0cf;
  text-decoration: none;
  transition: 0.3s;
  font-size: 2em;
}

.App-link:hover,
.App-link:hover .linkText {
  opacity: 1;
  color: #d3bf09;
}

.App-link:nth-of-type(odd):hover {
  transform: scale(1.5) rotate(5deg);
}

.App-link:nth-of-type(even):hover {
  transform: scale(1.5) rotate(-5deg);
}

.App-link .linkText {
  color: #09d3ac;
  opacity: 0;
  font-size: 0.4em;
  transition: 0.3s;
}

.icon {
  display: flex;
  margin: 0 10px;
}

.Links {
  margin: 2rem 0;
  display: flex;
  width: 100%;
  max-width: 500px;
}

.image {
  border-radius: 50%;
  border: 8px solid white;
}
